import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['startsAtDescription', 'endsAtDescription', 'cameraId', 'startsAtParam', 'endsAtParam', 'fromSlider', 'toSlider']

  connect() {
    this.isStop = true;
    this.now = new Date

    this.fillSlider(this.fromSliderTarget, this.toSliderTarget, '#C6C6C6', '#25daa5', this.toSliderTarget);
    this.setToggleAccessible(this.toSliderTarget);

    this.fromSliderTarget.oninput = () => this.controlFromSliderUpd();
    this.toSliderTarget.oninput = () => this.controlFromSliderUpd();
  }

  onSliderChange(e) {
    this.controlFromSliderUpd()
    this.loadImage(e ? true : false);
  }

  loadImage(once = false) {
    if (this.isStop && !once) { return }
    // TODO: Stop if end of player
    const self = this;

    // debugger
    Api.toDataURL(this.imagePath(), (dataUrl) => {
      const img = new Image()

      img.onload = () => {
        self.element.querySelector('.image').style.backgroundImage = `url(${dataUrl})`;
        if (!once) { self.nextImage() }
      }

      img.onerror = (e) => {
        // alert('failed to load')
        // self.stop();
        if (!once) { self.nextImage() }
      }

      img.src = dataUrl
    }, (xhr) => {
      console.log("Some Error")

      // if (xhr.status == 404) {
      //   self.setState({ errors: ['Lost connection to the camera'] })
      // } else {
      //   self.setState({ errors: ['Can\'t connect to the camera'] })
      // }

      alert("ERROR");
    })
  }

  imagePath() {
    const imagePath = encodeURIComponent(`/${this.cameraIdTarget.value}/${moment(this.sliderTime).format('YYYYMMDD_HH/YYYY-MM-DD HH:mm:ss')}.jpg`);
    return `/cameras/${this.cameraIdTarget.value}/timelapses/download?path=${imagePath}`;
  }

  nextImage() {
    setTimeout(() => {
      this.fromSliderTarget.value = parseInt(this.fromSliderTarget.value) + 1
      this.onSliderChange();
    }, 500)
  }

  play(el) {
    this.isStop = false;
    this.element.classList.add('play')
    this.element.classList.remove('stoped')
    this.loadImage()
  }

  stop() {
    this.isStop = true
    this.element.classList.add('stoped')
    this.element.classList.remove('play')
  }

  controlFromSliderUpd() {
    this.sliderTime = moment.unix((moment(this.now).unix() - (parseInt(this.fromSliderTarget.max) - parseInt(this.fromSliderTarget.value)) * 5));
    const endsAtTime = moment.unix((moment(this.now).unix() - (parseInt(this.toSliderTarget.max) - parseInt(this.toSliderTarget.value)) * 5));
    
    this.startsAtParamTarget.value = this.sliderTime
    this.endsAtParamTarget.value = endsAtTime

    this.startsAtDescriptionTarget.innerHTML = this.sliderTime.format('YYYY-MM-DD HH:mm:ss');
    this.endsAtDescriptionTarget.innerHTML = endsAtTime.format('YYYY-MM-DD HH:mm:ss');

    this.fillSlider(this.fromSliderTarget, this.toSliderTarget, '#C6C6C6', '#25daa5', this.toSliderTarget);

    if (this.sliderTime > endsAtTime) {
      this.fromSliderTarget.value = this.toSliderTarget.value;
      this.stop()
    }
  }

  fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
    const rangeDistance = to.max - to.min;
    const fromPosition = from.value - to.min;
    const toPosition = to.value - to.min;
    controlSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition) / (rangeDistance) * 100}%,
      ${rangeColor} ${((fromPosition) / (rangeDistance)) * 100}%,
      ${rangeColor} ${(toPosition) / (rangeDistance) * 100}%, 
      ${sliderColor} ${(toPosition) / (rangeDistance) * 100}%, 
      ${sliderColor} 100%)`;
  }

  setToggleAccessible(currentTarget) {
    if (Number(currentTarget.value) <= 0) {
      this.toSliderTarget.style.zIndex = 2;
    } else {
      this.toSliderTarget.style.zIndex = 0;
    }
  }
}
