import pLimit from 'p-limit'

const sendRequest = ({ path, method, params, onSuccess, onFailure }) => {
  let baseParams = {
    method: method,
    headers: headers(),
  }

  if (params) { baseParams = {...baseParams, body: JSON.stringify(params || {}) } }

  fetch(path, baseParams)
  .then(function (res) {
    if (res.status == 401) {
      // history.push('/login')
    }
    return res.json()
  })
  .then((response) => {
    const { data } = response

    if (!data && method == "DELETE") { return onSuccess() }

    if (typeof data === 'object' && data.errors) {
      // data.errors.map((error) => { notificationService.push(error, 'warning') })

      if (onFailure) { onFailure(data) }
    } else {
      if (onSuccess) { onSuccess(data) };
    }
  }).catch((err) => {
    if (onFailure) { onFailure(err) }
    // notificationService.push(['Something went wrong'], 'error')
    console.log(err)
  });
}

const csrfToken = () => { return document.querySelector('meta[name="csrf-token"]').content }

const get = (path, onSuccess, onFailure) => {
  sendRequest({ method: "GET", path: path, onSuccess: onSuccess, onFailure: onFailure });
}

const _get = (path, params = {}, onSuccess, onFailure) => {
  // const query = new URLSearchParams(params).toString()
  const fullPath = path.includes('?') ? `${path}&${serialize(params)}` : `${path}?${serialize(params)}`
  sendRequest({ method: "GET", path: fullPath, onSuccess: onSuccess, onFailure: onFailure })
}

const post = (path, data = {}, onSuccess, onFailure) => {
  sendRequest({ method: "POST", path: path, params: data, onSuccess: onSuccess, onFailure: onFailure })
}

const put = (path, data = {}, onSuccess, onFailure) => {
  sendRequest({ method: "PUT", path: path, params: data, onSuccess: onSuccess, onFailure: onFailure })
}

const destroy = (path, onSuccess, onFailure) => {
  sendRequest({ method: "DELETE", path: path, onSuccess: onSuccess, onFailure: onFailure });
}

const headers = () => {
  // const authHeader = userToken() ? { 'Authorization': `Bearer ${userToken()}` } : {};
  return { 'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrfToken() };
}

const userToken = () => {
  return localStorage.getItem('userToken')
}

// Copied from stackoverflow. Just converts obj to string query
const serialize = (obj, prefix) => {
  let str = [], p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

const limit = pLimit(5)
const toDataURL = (url, onSuccess, onFailure) => {
  // Wrap the request logic in a function and pass it to limit()
  limit(() => new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function(e) {
      if (xhr.readyState != 4) { return }

      if (xhr.status == 200) {
        var reader = new FileReader();
        reader.onloadend = function() {
          if (onSuccess) { onSuccess(reader.result); }
          resolve(reader.result); // Resolve the promise with the result
        }
        reader.readAsDataURL(xhr.response);
      } else {
        console.log('Something went wrong: ' + xhr.statusText);
        if (onFailure) { onFailure(xhr); }
        reject(xhr); // Reject the promise on failure
      }
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', 'Bearer ' + userToken());
    xhr.send();
  })).then((base64Image) => {})
     .catch((error) => console.error("Failed to load image:", error));
};

window.Api = {
  get,
  _get,
  post,
  put,
  destroy,
  toDataURL,
  userToken
}
