const LAYOUTS = ['1', '2', '2-v', '4']

export const setPreset = (presetIndex, cameraId, isStream = false) => {
  const currentPresets = presets();
  currentPresets[presetIndex] = { cameraId, isStream }
  localStorage.setItem('presets', JSON.stringify(currentPresets));
}

// presets = {
//   1: {
//     cameraId: 1,
//     isStream: true
//   }
// }
export const presets = () => {
  try {
    return JSON.parse(localStorage.getItem('presets')) || {}
  } catch {
    return {}
  }
}

export const currentLayout = () => {
  return localStorage.getItem('currentLayout') || LAYOUTS[0];
}

export const setCurrentLayout = (value) => {
  localStorage.setItem('currentLayout', value);
}
