import { Controller } from "@hotwired/stimulus"
import { setPreset, presets } from "../shared/live_helper";

export default class extends Controller {
  static targets = ['cameraSelection', 'presetSelect', 'imageView', 'isStream', 'presetIndex', 'isDirectSnapshot']

  connect() {
    const self = this
    this.timerId = null;
    this.isDisconnected = false;

    const preset = presets()[this.presetIndexTarget.value]
    this.cameraSelectionTarget.value = preset?.cameraId || ''
    this.isStreamTarget.checked = preset?.isStream || false

    this.cameraSelectionTarget.addEventListener('change', () => {
      setPreset(self.presetIndexTarget.value, self.cameraSelectionTarget.value, self.isStreamTarget.checked)
      self.addLoading();
      self.initPtz();
      self.runLiveView();
    })
    this.isStreamTarget.addEventListener('change', () => {
      setPreset(self.presetIndexTarget.value, self.cameraSelectionTarget.value, self.isStreamTarget.checked)
      this.addLoading();
      this.runLiveView();
    })
    this.cameraSelectionTarget.dispatchEvent(new Event('change'));
  }

  addLoading() {
    this.element.classList.add('live-layout__loading')
  }

  removeLoading() {
    this.element.classList.remove('live-layout__loading')
  }

  disconnect() {
    this.isDisconnected = true;
    this.resetImage();
  }

  resetImage() {
    this.imageViewTarget.src = ''
    // Reset callbacks
    this.imageViewTarget.onload  = null;
    this.imageViewTarget.onerror = null;
    // this.imageViewTarget.removeAttribute('src')
    clearTimeout(this.timerId);
    this.addLoading()
  }

  runLiveView() {
    this.resetImage()

    if (this.isStreamTarget.checked) {
      this.runStreamLiveView()
    } else {
      this.runSnapshotLiveView()
    }
  }

  runStreamLiveView() {
    if (!this.cameraSelectionTarget.value) return
    if (this.isDisconnected) return

    self = this
    this.imageViewTarget.src = Routes.liveStream(this.cameraSelectionTarget.value) // 'http://192.168.31.58:81/stream'
    // this.imageViewTarget.src = 'http://192.168.31.58:81/stream'

    this.imageViewTarget.onload = () => {
      this.removeLoading();
      this.checkStreamIsWorking();
    }

    this.imageViewTarget.onerror = () => {
      self.resetImage();
      self.timerId = setTimeout(() => self.runLiveView(), 1500)
    }
  }

  checkStreamIsWorking() {
    const self = this
    this.timerId = setTimeout(() => {
      if (self.isDisconnected) return

      if (self.imageViewTarget.naturalWidth == 0 || self.imageViewTarget.naturalHeight == 0) {
        self.runLiveView()
      } else {
        self.checkStreamIsWorking()
      }
    }, 1000)
  }

  runSnapshotLiveView(delay = 100) {
    if (this.isDisconnected) { return }

    const self = this;

    this.timerId = setTimeout(() => {
      if (!this.cameraSelectionTarget.value) return
      const params = self.imageViewTarget.width && self.imageViewTarget.height ? { w: self.imageViewTarget.width, h: self.imageViewTarget.height } : {}

      const url = this.isDirectSnapshot() ?
                    Routes.liveDirectSnapshot(this.cameraSelectionTarget.value, params) :
                    Routes.liveSnapshot(this.cameraSelectionTarget.value, params)

      Api.toDataURL(url, (dataUrl) => {
        if (this.isStreamTarget.checked) return 
        if (this.cameraSelectionTarget.value == '') return
        
        this.removeLoading();
        this.imageViewTarget.src = dataUrl
        this.runSnapshotLiveView();
      }, (xhr) => {
        this.addLoading();

        // if (xhr.status == 404) {
        //   self.setState({ errors: ['Lost connection to the camera'] })
        // } else {
        //   self.setState({ errors: ['Can\'t connect to the camera'] })
        // }

        self.runSnapshotLiveView(1500)
      })

    // TODO: Make less delay for local? Or change delay according to connection
    }, delay)
  }

  initPtz() {
    const selectedCamera = this.cameraSelectionTarget.selectedOptions[0];
    if (selectedCamera && selectedCamera.dataset.ptz == 'true') {
      this.element.classList.add('ptz')
      this.fetchPtzPresets()
    } else {
      this.element.classList.remove('ptz')
    }
  }

  isDirectSnapshot() {
    const selectedCamera = this.cameraSelectionTarget.selectedOptions[0];
    if (selectedCamera && selectedCamera.dataset.isDirectSnapshot == 'true') return true
    return false
  }

  fetchPtzPresets() {
    Api.post(Routes.cameraPtz(this.cameraSelectionTarget.value, 'presets'), {}, (presets) => {
      presets.forEach(({ id, name, selected }) => {
        const option = document.createElement('option');
        
        option.textContent = name;
        option.value = id;
        option.selected = selected
        this.presetSelectTarget.appendChild(option);
      });
    })
  }

  sendPtzAction(e) {
    const action = e.target.dataset.ptzAction
    let data = {
      ptz_params: {}
    }

    if (action == 'set_preset') {
      data.ptz_params.preset_id = e.target.selectedOptions[0].value
      if (!data.ptz_params.preset_id) return 
    }

    Api.post(Routes.cameraPtz(this.cameraSelectionTarget.value, action), data)
  }
}
