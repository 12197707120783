import { Controller } from "@hotwired/stimulus"
import { presets, currentLayout, setCurrentLayout } from "../shared/live_helper"

export default class extends Controller {
  static targets = ['form', 'layoutCountSelect']

  connect() {
    this.submitForm()
    this.layoutCountSelectTarget.value = currentLayout()
    this.layoutCountSelectTarget.addEventListener('change', (e) => {
      setCurrentLayout(e.target.value);
      this.submitForm();
    })
  }

  submitForm() {
    this.formTarget.action = Routes.liveFetchLayout()
    this.formTarget.querySelector('[name="live_params[current_layout]"]').value = currentLayout()
    this.formTarget.querySelector('[name="live_params[presets]"]').value = JSON.stringify(presets())
    this.formTarget.method = 'GET';
    this.formTarget.requestSubmit()
  }
}
